import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../components/Context/AuthContext";
import { useFirestore } from "react-redux-firebase";
import { merchant } from "../../../../utils/firestore";
import PaymentCard from "../PaymentCard";
import { requestOnboardingPaypalSeller } from "./requestOnboardingPaypalSeller";
import useHandlePayPalDisconnect from "./useHandlePaypalDisconnect";
import { useLocation } from 'react-router-dom';
import authPaypalPlatform from "./authPaypalPlattform";

interface IPayPalState {
  connected: boolean;
  merchantId: string | null;
  loading: boolean;
}

const PaypalConnect = () => {
  const [paypalState, setPaypalState] = useState<IPayPalState>({
    connected: false,
    merchantId: null,
    loading: false,
  });

  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const firestore = useFirestore();
  const { handlePaypalDisconnect } = useHandlePayPalDisconnect();
  const location = useLocation();

  //set state 
  useEffect(() => {
    const fetchPayPalData = async () => {
      if (!mid) return;
      setPaypalState(prevState => ({ ...prevState, loading: true }));
      const merchantSnapshot = await merchant(mid);
      const merchantDoc = merchantSnapshot.data();
      if (merchantSnapshot.exists && merchantDoc?.paymentprovider?.paypal) {
        setPaypalState({
          connected: true,
          merchantId: merchantDoc.paymentprovider.paypal.merchant_id,
          loading: false,
        });
      } else {
        setPaypalState(prevState => ({ ...prevState, loading: false }));
      }
    };
    fetchPayPalData();
  }, [mid, firestore]);

  // New useEffect for handling PayPal onboarding
  useEffect(() => {
    const handlePayPalOnboarding = async () => {
      const params = new URLSearchParams(location.search);
      const paypalMerchantId = params.get("merchantIdInPayPal");
      
      if (paypalMerchantId !== null && mid) {
        setPaypalState(prevState => ({ ...prevState, loading: true }));
        try {
          const data = await authPaypalPlatform();
          const accessToken = data.access_token;
          
          const apiUrl = `${process.env.REACT_APP_PAYPAL_BASE_URL}/v1/customer/partners/${process.env.REACT_APP_PAYPAL_MERCHANT_ID}/merchant-integrations/${paypalMerchantId}`;
          
          const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          
          const merchantIntegrationStatus = await response.json();

          if (
            merchantIntegrationStatus.merchant_id &&
            merchantIntegrationStatus.payments_receivable
          ) {
            setPaypalState({
              connected: true,
              merchantId: merchantIntegrationStatus.merchant_id,
              loading: false,
            });

            // Store PayPal connection info in Firestore
            await firestore.collection('merchants').doc(mid).set(
              {
                paymentprovider: {
                  paypal: merchantIntegrationStatus,
                },
              },
              { merge: true }
            );
          }
        } catch (error) {
          console.error("Error handling PayPal connection:", error);
          setPaypalState({
            connected: false,
            merchantId: null,
            loading: false,
          });
        }
      }
    };

    handlePayPalOnboarding();
  }, [location.search, mid, firestore]);

  

  const handleDisconnect = async () => {
    await handlePaypalDisconnect(mid, setPaypalState).then(()=>{
      setPaypalState(prevState => ({ ...prevState, connected: false }));
    })
  };

  return (
    <PaymentCard
      title="PayPal"
      smallTitle={paypalState.connected ? "verbunden" : ""}
      actions={
        paypalState.connected ? (
          <div className="w-full m-auto">
            <div
              className="text-red-900 font-black px-6 py-4 cursor-pointer float-right rounded-xl hover:bg-red-900 hover:text-white w-max "
              onClick={handleDisconnect}>
              {t("actions.delete")}
            </div>
          </div>
        ) : null
      }
      loading={paypalState.loading}>
      {paypalState.connected ? (
        <div className="m-4 text-left">
          <table className="bg-none text">
            <tbody>
              <tr>
                <td>
                  <b>Merchant ID:</b>
                </td>
                <td className="pl-4">{paypalState.merchantId}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <p>Verbinden Sie jetzt Ihr PayPal Konto mit Ihrem Account</p>
          <button onClick={() => requestOnboardingPaypalSeller(mid)}>
            <img
              src={require("../../../../assets/paypal_verbinden.png")}
              alt="PayPal Logo"
              style={{ height: '102px', width: '330px' }}
            />
          </button>
        </div>
      )}
    </PaymentCard>
  );
};

export default PaypalConnect;
