import { useContext, useEffect, useState } from "react";
import { Functions } from "../../../firebase";
import { AuthContext } from "../../../components/Context/AuthContext";
import { useTranslation } from "react-i18next";
import MollieConnectContent from "./Mollie/MollieConnectContent/MollieConnectContent";
import MollieConnectedContent from "./Mollie/MollieConnectedContent/MollieConnectedContent";
import MollieChooseProfileContent from "./Mollie/MollieChooseProfileContent/MollieChooseProfileContent";
import { useFirestore } from "react-redux-firebase";
import { merchant, merchantRef } from "../../../utils/firestore";
import Button from "../../../components/Button";
import SettingsWrapper from "../../../components/SettingsWrapper";
import Modal from "../../../components/Modal";
import LoadingComponent from "../../../components/Loading/LoadingWrapper";
import { useLocation } from "react-router-dom";
import { Merchant } from "../../../types/Merchant";
import PaymentCard from "./PaymentCard";
import PaypalConnect from "./Paypal";

enum PaymentStates {
  INIT,
  CONNECTED,
  READY,
}

// type PaymentProps = {
//   history: any;
//   location: any;
//   match: any;
// };

interface IMollieState {
  state: PaymentStates;
  currentProfile: string;
}


interface IPaymentState {
  loading: boolean;
  modalIsOpen: boolean;
  mollie: IMollieState;
}

const PaymentSetting = () => {
  const [paymentState, setPaymentState] = useState<IPaymentState>({
    loading: false,
    modalIsOpen: false,
    mollie: {
      state: PaymentStates.INIT,
      currentProfile: "",
    },
   
  });

  const [connectionLoading, setConnectionLoading] = useState<boolean>(false);
  const [merchantObject, setMerchantObject] = useState<Merchant | undefined>();
  const { t } = useTranslation();
  const { mid } = useContext(AuthContext);
  const firestore = useFirestore();
  const location = useLocation();

  const deleteMollieAccount = async () => {
    closeModal();
    setPaymentState(prevState => ({ ...prevState, loading: true }));
    const merchantSnapshot = await merchant(mid || "-");
    if (merchantSnapshot.exists) {
      merchantRef(mid || "-")
        .update({
          "paymentprovider.mollie": {},
        })
        .then(() => {
          updateMollieState({ state: PaymentStates.INIT });
          setPaymentState(prevState => ({ ...prevState, loading: false }));
        });
    }
  };

  const storeMollieProfile = async (profile: string) => {
    setPaymentState(prevState => ({ ...prevState, loading: true }));
    const merchantSnapshot = await merchant(mid || "-");
    if (merchantSnapshot.exists) {
      merchantRef(mid || "-")
        .set(
          {
            paymentprovider: {
              mollie: { profileid: profile },
            },
          },
          { merge: true }
        )
        .then(() => {
          updateMollieState({ state: PaymentStates.READY, currentProfile: profile });
          setPaymentState(prevState => ({ ...prevState, loading: false }));
        })
        .catch(() => {
          setPaymentState(prevState => ({ ...prevState, loading: false }));
        });
    } else {
      setPaymentState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const updateMollieState = (newMollieState: Partial<IMollieState>) => {
    setPaymentState(prevState => ({
      ...prevState,
      mollie: { ...prevState.mollie, ...newMollieState },
    }));
  };

  

  useEffect(() => {
    const refreshData = async () => {
      const params = new URLSearchParams(location.search);
      const accesstoken = params.get("code");
      const oauthState = params.get("state");
      if (!mid) {
        return;
      }
      setPaymentState(prevState => ({ ...prevState, loading: true }));
      if (accesstoken) {
        Functions()
          .httpsCallable("calls-oauth-validateState")({
            state: oauthState,
            application: `mollie`,
          })
          .then((result) => {
            if (result.data === true) {
              Functions()
                .httpsCallable("calls-mollie-getToken")({
                  authCode: accesstoken,
                })
                .then(() => {
                  updateMollieState({ state: PaymentStates.CONNECTED });
                  setPaymentState(prevState => ({ ...prevState, loading: false }));
                });
     
            }
          })
          .finally(() => {
            setPaymentState(prevState => ({ ...prevState, loading: false }));
          });
      } else {
        const merchantSnapshot = await merchant(mid || "-");
        const merchantDoc = merchantSnapshot.data();
        if (merchantSnapshot.exists && merchantDoc?.paymentprovider) {
          if (
            merchantDoc.paymentprovider.mollie &&
            merchantDoc.paymentprovider.mollie.access_token &&
            merchantDoc.paymentprovider.mollie.profileid
          ) {
            updateMollieState({ 
              state: PaymentStates.READY, 
              currentProfile: merchantDoc.paymentprovider.mollie.profileid 
            });
          } else if (
            merchantDoc.paymentprovider.mollie &&
            merchantDoc.paymentprovider.mollie.access_token
          ) {
            updateMollieState({ state: PaymentStates.CONNECTED });
          }

          
        }
        setPaymentState(prevState => ({ ...prevState, loading: false }));
      }
    };
    refreshData();
    //fetch merchant by mid from firestore 
    const merchantObject = merchant(mid || "-").then((merchant) =>  merchant.data())
    merchantObject.then((merchant) => {
      setMerchantObject(merchant)
    })
    
  }, [location.search, mid, firestore]);

  const openModal = () => {
    setPaymentState(prevState => ({ ...prevState, modalIsOpen: true }));
  };
  const closeModal = () => {
    setPaymentState(prevState => ({ ...prevState, modalIsOpen: false }));
  };


  let mollieComponent;
  let mollieAction;
  switch (paymentState.mollie.state) {
    case PaymentStates.READY:
      mollieComponent = (
        <MollieConnectedContent profileId={paymentState.mollie.currentProfile} />
      );
      mollieAction = (
        <div className="w-full m-auto">
          <div
            className="text-red-900 font-black px-6 py-4 cursor-pointer float-right rounded-xl hover:bg-red-900 hover:text-white w-max "
            onClick={() => openModal()}>
            {t("actions.delete")}
          </div>
        </div>
      );
      break;
    case PaymentStates.CONNECTED:
      mollieComponent = (
        <MollieChooseProfileContent storeProfile={storeMollieProfile} />
      );
      break;
    default:
      mollieComponent = (
        <MollieConnectContent setConnectionLoading={setConnectionLoading} />
      );
  }


  return (
    <SettingsWrapper title={t("components.settings.payments.mypayments")}>
      <Modal
        onRequestClose={closeModal}
        isOpen={paymentState.modalIsOpen}
        title={t("components.settings.payments.delete_account_title")}
        body={
          <>
            <p className="w-1/2">
              {t("components.settings.payments.delete_account_text")}
            </p>
            <div className="w-max">
              <Button
                title={t("components.settings.payments.delete_account_skip")}
                onClickListener={() => closeModal()}
              />
              <Button
                title={t("components.settings.payments.delete_account_delete")}
                color="bg-red-900"
                onClickListener={() => deleteMollieAccount()}
              />
            </div>
          </>
        }
      />
      <LoadingComponent isLoading={paymentState.loading}>
        <PaymentCard
          title="Mollie"
          smallTitle={paymentState.mollie.state === PaymentStates.READY ? "verbunden" : ""}
          actions={mollieAction}
          loading={connectionLoading}>
          {mollieComponent}
        </PaymentCard>
        {merchantObject?.paypalBetaMerchant && <PaypalConnect />}

      </LoadingComponent>
      
    </SettingsWrapper>
  );
};

export default PaymentSetting;
