import { useFirestore } from "react-redux-firebase";
import { useState, useCallback } from "react";

export const useHandlePayPalDisconnect = () => {
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);

  const handlePaypalDisconnect = useCallback(
    async (mid: string | null, setPaymentState: any) => {
      if (!mid) {
        throw new Error("Merchant ID is not available");
      }
      try {
        setLoading(true);
        setPaymentState((prevState) => ({ ...prevState, loading: true }));

        // Remove PayPal info from Firestore
        await firestore
          .collection("merchants")
          .doc(mid)
          .set(
            {
              paymentprovider: {
                paypal: null,
              },
            },
            { merge: true }
          );

        // Update local state
        setPaymentState((prevState) => ({
          ...prevState,
          paypalConnected: false,
          paypalMerchantId: null,
        }));
      } catch (error) {
        console.error("Error disconnecting PayPal:", error);
      } finally {
        setLoading(false);
        setPaymentState((prevState) => ({ ...prevState, loading: false }));
      }
    },
    [firestore]
  );

  return { handlePaypalDisconnect, loading };
};

export default useHandlePayPalDisconnect;
