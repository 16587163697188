import firebase from "firebase/compat/app";
import moment from "moment";
import i18n from "../locales/i18n";
import { Abo } from "../types/Abo";
import { Amount } from "../types/Amount";
import { ProfileStatus } from "../types/Profile";
import { BookingStatus } from "../types/Management/Booking";

export const formatDateFromFirebaseTimestamp = (
  timestamp: firebase.firestore.Timestamp,
  format: string = "DD.MM.YYYY HH:mm:ss"
): string => {
  const date = new firebase.firestore.Timestamp(
    timestamp.seconds,
    timestamp.nanoseconds
  ).toDate();
  timestamp = firebase.firestore.Timestamp.fromDate(date);
  return moment(timestamp.toDate()).format(format);
};

export const formatDate = (
  date: Date,
  format: string = "DD.MM.YYYY HH:mm:ss"
): string => {
  return moment(date).format(format);
};

export const translateUserState = (state: number): string => {
  switch (state) {
    case 1:
      return i18n.t("components.settings.organisation.user.status.invited");
    case 2:
      return i18n.t("components.settings.organisation.user.status.active");
    case 3:
      return i18n.t("components.settings.organisation.user.status.blocked");
    case 4:
      return i18n.t("components.settings.organisation.user.status.deleted");
    case 0:
    default:
      return i18n.t("components.settings.organisation.user.status.disabled");
  }
};

export const translateUserRole = (state: number): string => {
  switch (state) {
    case 0:
      return i18n.t("components.settings.organisation.user.role.owner");
    case 1:
      return i18n.t("components.settings.organisation.user.role.admin");
    case 2:
    default:
      return i18n.t("components.settings.organisation.user.role.guest");
  }
};

export const translateTransactionState = (state: number): string => {
  switch (state) {
    case -4:
      return i18n.t("states.transaction.refunded");
    case -3:
      return i18n.t("states.transaction.refunded");
    case -2:
      return i18n.t("states.transaction.failed");
    case -1:
      return i18n.t("states.transaction.failed");
    case 0:
      return i18n.t("states.transaction.created");
    case 1:
      return i18n.t("states.transaction.open");
    case 2:
      return i18n.t("states.transaction.pending");
    case 3:
      return i18n.t("states.transaction.paid");
    case 4:
      return i18n.t("states.transaction.complete");
    default:
      return i18n.t("states.transaction.failed");
  }
};

export const translateAboState = (state: number): string => {
  switch (state) {
    case 0:
      return i18n.t("states.abo.created");
    case 1:
      return i18n.t("states.abo.active");
    case 2:
      return i18n.t("states.abo.paused");
    case 3:
      return i18n.t("states.abo.finished");
    case 4:
      return i18n.t("states.abo.error");
    case 5:
      return i18n.t("states.abo.deleted");
    default:
      return i18n.t("states.abo.error");
  }
};

export const translateBookingState = (state: BookingStatus): string => {
  switch (state) {
    case BookingStatus.Active:
    case BookingStatus.Open:
      return i18n.t("states.booking.active");
    case BookingStatus.Canceled:
    case BookingStatus.Deleted:
    default:
      return i18n.t("states.booking.canceled");
  }
};

export const translateKeyState = (state: ProfileStatus): string => {
  switch (state) {
    case ProfileStatus.Requested:
      return i18n.t("states.key.requested");
    case ProfileStatus.Valid:
      return i18n.t("states.key.active");
    case ProfileStatus.Disabled:
      return i18n.t("states.key.disabled");
    default:
      return "n/a";
  }
};

/**
 * Creates an array from QuerySnapshot
 * Every key that ends with 'Ref' is changed to 'Path'
 * @param querySnapshot QuerySnapshot of which the array should be created
 * @returns returns a array
 */
export const createArrayByProperty = <T>(
  querySnapshot: firebase.firestore.QuerySnapshot
): Array<any> => {
  let array: any[] = [];
  querySnapshot.forEach((document) => {
    //Change Reference to Path because of serialization problems
    const data = replaceReferencesWithPath(document.data());
    array.push({
      data: data as T,
      path: document.ref.path,
      id: document.id,
    });
  });
  return array;
};

const replaceRefrences = (data: object): object => {
  const newData = Object.keys(data || {}).map((key) => {
    if (key.endsWith("Ref")) {
      return [
        `${key.substring(0, key.length - 3)}Path`,
        (data[key] = data[key].path),
      ];
    }
    return [key, data[key]];
  });
  return Object.fromEntries(newData);
};

export const replaceReferencesWithPath = (
  data: firebase.firestore.DocumentData
): firebase.firestore.DocumentData => {
  const newData = Object.keys(data).map((key) => {
    if (key.endsWith("Ref")) {
      return [
        `${key.substring(0, key.length - 3)}Path`,
        (data[key] = data[key].path),
      ];
    }
    if (typeof data[key] === "object") {
      return [key, (data[key] = replaceRefrences(data[key]))];
    }
    return [key, data[key]];
  });
  return Object.fromEntries(newData);
};

export const sortArrayByProperty = (
  array: any[],
  property: any,
  order: "ASC" | "DESC"
): Array<any> => {
  return array.sort((n1, n2) => {
    if (order === "ASC") {
      return n2.data[property] - n1.data[property];
    } else {
      return n1.data[property] - n2.data[property];
    }
  });
};

export const calculateNextExecution = (abo: Abo) => {
  let from: Date;
  if (abo.startDate !== undefined) {
    try {
      from = abo.startDate.toDate();
    } catch {
      from = new firebase.firestore.Timestamp(
        abo.startDate.seconds,
        abo.startDate.nanoseconds
      ).toDate();
    }
  } else {
    from = abo.createTime.toDate();
  }
  const [value, unit] = abo.interval.split(" ");
  let returnDate: Date | undefined;
  switch (unit) {
    case "days":
      returnDate = new Date(from.setDate(from.getDate() + parseInt(value)));
      break;
    case "weeks":
      returnDate = new Date(from.setDate(from.getDate() + parseInt(value) * 7));
      break;
    case "months":
      returnDate = new Date(from.setMonth(from.getMonth() + parseInt(value)));
      break;
    default:
      throw Error("Unknown interval unit!");
  }
  return new Date(returnDate.setHours(7, 30, 0));
};

export const getUnitFromInterval = (interval: string): string => {
  const unit = interval.split(" ");
  switch (unit[1]) {
    case "days":
      return i18n.t("utils.day");
    case "weeks":
      return i18n.t("utils.week");
    case "months":
      return i18n.t("utils.month");
    default:
      return "";
  }
};

export const getAmountWithSymbolAsString = (amount: Amount): string => {
  switch (amount.currency) {
    case "USD":
      return `$ ${amount.value}`;
    case "EUR":
      return `${amount.value} €`;
    default:
      return `${amount.value} ${amount.currency}`;
  }
};

export const getTailwindColumnClass = (cols: number) => {
  switch (cols) {
    case 0:
      return " grid-cols-0";
    case 1:
      return " grid-cols-1";
    case 2:
      return " grid-cols-2";
    case 3:
      return " grid-cols-3";
    case 4:
      return " grid-cols-4";
    case 5:
      return " grid-cols-5";
    case 6:
      return " grid-cols-6";
    case 7:
      return " grid-cols-7";
    case 8:
      return " grid-cols-8";
    default:
      return " grid-cols-2";
  }
};

export const capitalize = (text?: string) => {
  if (!text) return undefined;
  return text.toString().charAt(0).toUpperCase() + text.toString().slice(1);
};
