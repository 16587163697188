import authPaypalPlatform from "./authPaypalPlattform";

export const requestOnboardingPaypalSeller = (mid: string | null) => {
  if (!mid) {
    throw new Error("Merchant ID is not available");
  }

  return authPaypalPlatform().then((token) => {
    fetch(
      `${process.env.REACT_APP_PAYPAL_BASE_URL}/v2/customer/partner-referrals`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
          "PayPal-Partner-Attribution-Id": "WebStollenGmbH_SI_Vaulting",
        },
        body: JSON.stringify({
          tracking_id: "tracking_" + mid,
          partner_config_override: {
            return_url:
              process.env.REACT_APP_PAYPAL_REDIRECT_URL + "settings/payment",
          },
          operations: [
            {
              operation: "API_INTEGRATION",
              api_integration_preference: {
                rest_api_integration: {
                  integration_method: "PAYPAL",
                  integration_type: "THIRD_PARTY",
                  third_party_details: {
                    features: [
                      "PAYMENT",
                      "REFUND",
                      "VAULT",
                      "BILLING_AGREEMENT",
                    ],
                  },
                },
              },
            },
          ],
          products: ["PPCP", "ADVANCED_VAULTING"],
          capabilities: ["PAYPAL_WALLET_VAULTING_ADVANCED"],
          legal_consents: [
            {
              type: "SHARE_DATA_CONSENT",
              granted: true,
            },
          ],
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const actionUrl = data.links.find(
          (link) => link.rel === "action_url"
        ).href;
        window.open(actionUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error generating PayPal onboarding link:", error);
      });
  });
};
