const authPaypalPlatform = () => {
  return fetch(`${process.env.REACT_APP_PAYPAL_BASE_URL}/v1/oauth2/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Basic " +
        btoa(
          `${process.env.REACT_APP_PAYPAL_ACCOUNT_CLIENT_ID}:${process.env.REACT_APP_PAYPAL_ACCOUNT_CLIENT_SECRET}`
        ),
      "PayPal-Partner-Attribution-Id": "WebStollenGmbH_SI_Vaulting",
    },
    body: "grant_type=client_credentials",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching PayPal token:", error);
      throw error;
    });
};
export default authPaypalPlatform;
